// Define color variables
$primary-bg: #F7F7F7;
$primary-color: #333333;
$primary-accent: #FF5F3C;
$secondary-accent: #FFC107;
$tertiary-accent: #4ABDAC;
$header-bg: #F7F7F7;

// Other common entries for theming
$body-bg: #FFFFFF;
$font-family: Arial, sans-serif;
$font-size-base: 16px;
$font-color: #333333;
