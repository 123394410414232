.swa-calendar-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

button {
  padding: 8px 16px;
  font-size: 16px;
  margin: 10px;
  cursor: pointer;
}

.swa-calendar-month {
  margin-top: 20px;
}
